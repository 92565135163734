
.mobile-fixed{
    z-index: 100;
    transform: translateX(0px) !important;

    @media (max-width: 768px) {
        position: fixed !important;
        
        width: 100% !important;
        height: 100% !important;
        top: 0;
        left: 0;
        overflow-x: hidden;
        overflow-y: auto;

        .ant-drawer-content-wrapper{
            width: 100% !important;
        }
    }
}