@import './keyframes.scss';
@import './helper.scss';
@import './colors.scss';

.enrollments, .history, .athletes, .events, .documents{
    margin-bottom: 25px;
}

.c-title{
    border: 1px solid;
    border-width: 0 0 1px 0;
    padding-bottom: 6px !important;
    border-color: #f0f0f0;
    font-size: 1.4em !important;

    .c-sub-title{
        padding-left: 37px;
        display: block;
        font-size: .5em;

        @media (max-width: 768px) {
            /*display: none;*/
        }
    }
}

/*Lo usamos para que las tarjetas queden alineadas con el mismo alto*/
.ant-card-body{
    min-height: 175px;

    @media (max-width: 768px){
        min-height: auto !important;
    }
}

.athlete-box-icon,
.enrollment-box-icon{
    font-size: 1.2em !important;
    padding: 0;
    margin: 0;

    &.active{
        color: $green;
    }

    &.cancelled{
        color: $red;
    }

    &.finished{
        color: $violet;
    }

    &.pre{
        color: $orange;
    }

    &.unpaid{
        color: $unpaid;
    }
}

.block{
    display:block;
}



.pin-report-actions,
.custom-table-actions{
    font-size: 1.5em;
    margin-right:5px;

    &.record-unpinned,
    &.record-unselected{
        transition: 300ms ease all;
    }

    &.record-pinned{
        color: $secondary-color;
        transform: rotate(-45deg);
        transition: 300ms ease all;
    }

    &.record-selected{
        color: $secondary-color;
        transform: rotate(45deg);
        transition: 300ms ease all;
    }
}

.drawer-responsive{
    &.visible{
        .ant-drawer-content-wrapper{
            @media only screen and (max-width: 600px) {
                min-width: 100% !important;
                max-width: 100% !important;
                position: absolute;
                top:0;
                left: 0;
              }
        }
    }
}


//Botones que hay sobre los componentes
.b-component-actions{
    text-align: right;
    

    button[class*='btn-']{
        width: 160px;
        margin-left: 10px;
        margin-top: 10px;

        @media only screen and (max-width: 400px) {
            width: 100%;
            margin-bottom: 5px;
            margin-top: 5px;
            margin-left: 0px;
          }
    }
}

.form-buttons-container{
    margin: 5px 0;

    &.left{
        text-align: left;
    }

    &.right{
        text-align: right;
    }

    button{
        @media only screen and (max-width: 400px) {
            width: 100%;
        }
    }
    
}

.ant-btn-primary{
    background-color: $main-color;
    border-color: $main-color;
    color: $white;

    &.btn-secondary{
        background-color: $secondary-color;
        border-color: $secondary-color;
        color: $white;

        &:disabled{
            background-color: $disabled-color;
            border-color: $disabled-color;
        }
    }
}

.filter-buttons{
    button:not(.btn-antd){
        background-color: $secondary-color;
        border-color: $secondary-color;
        color: $white;
    }
}


.request-status{
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;

    &.inline{
        display: inline-flex;
        max-width: 20px !important;
        max-height: 20px !important;
        min-width: 20px !important;
        min-height: 20px !important;
        font-size: small;
        margin-right: 1px;
    }
    
    .status-icon{
        color: white;
    }

    &.pendiente{
        background-color: $request-pending;
    }

    &.ordenado{
        background-color: $request-ordered;
    }

    &.recepcionado{
        background-color: $request-delivered;
    }

    &.procesando{
        background-color: $request-processing;
    }

    &.finalizado{
        background-color: $request-finished;
    }

    &.cancelado{
        background-color: $request-canceled;
    }
}

.ant-btn-sm{
    &.ssm{
        height: 20px !important;
        font-size: 11px !important;
    }
}


.ant-input-group{
    &.ant-input-group-compact{
        &.inline-block{
            display: inline-block !important;
        }
    }
}





