.product-list{

    .btn-save{
        margin-right: 5px;
    }
    
    .reduced-price{
        font-weight: bold;
        display: block;
    }

    .t-product-list{
        margin-top: 10px;
    }
    
}