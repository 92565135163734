.quick-edit{

    .footer-add-patient{
        padding: 10px;
        text-align: right;
        .ant-typography{
            font-size: 14px !important;
        }
    }

    .quick-edit-form{
        text-align: right;
    }

    .ant-drawer-content-wrapper{
        /*
        (mobile < 768), (tablet < 992) else desktop
        */
        width: 100% !important;

        @media (min-width: 768px) {
            width: 60% !important;
        }

        @media (min-width: 993px) {
            width: 50% !important;
        }

        
    }
    

    .app-form-item{
        margin-bottom: 5px;
        text-align: left !important;
    }

    .btn-update{
        margin-bottom: 10px;
        display: inline-block;
        width: 150px;
    }
}


.add-patient-drawer{
    .ant-drawer-content-wrapper{
        /*
        (mobile < 768), (tablet < 992) else desktop
        */
        width: 100% !important;

        @media (min-width: 768px) {
            width: 60% !important;
        }

        @media (min-width: 993px) {
            width: 50% !important;
        }
    }
    
}