.app-header{
    position: relative;
    z-index: 10;
    max-width: 100%;
    background: #fff;
    box-shadow: 0 2px 8px #f0f1f2;
    min-height: 64px;
    
    @media(max-width: 768px){
        padding-top: 10px;
    }

    .logo-container,.search-container{
        display: flex;
        align-items: center;
    }

    .logo-container{
        justify-content: center;
        text-align: center;
        .logo{
            max-height: 50px;
        }
    }

   
}