
.box-component{
    margin: 3px;
    padding: 5px;
}

.request-status-tag{
    display: flex;
    align-items: center;
    justify-content: left;
}

.month-selector{
    text-align: right;
    margin-bottom: 15px;
    margin-top: 15px;
    float: right;
}


.calendar{
    .events{
        padding:0;
        li{
            list-style: none;
        }
    }

    .ant-picker-calendar-date-content{
        overflow: visible !important;
        height: auto !important;
    }

    .ant-picker-cell,
    .ant-picker-cell-in-view{
                vertical-align: top;
    }
    

    .ant-badge-status-pendiente {
        background-color: #1c84c6;
    }
    .ant-badge-status-ordenado {
        background-color: #ED55B8;
    }
    .ant-badge-status-recepcionado {
        background-color: #1ab394;
    }
    .ant-badge-status-procesando {
        background-color: #f8ac59;
    }
    .ant-badge-status-finalizado {
        background-color: #ed5565;
    }
    .ant-badge-status-cancelado {
        background-color: darkgray;
    }

    .ant-badge-status-dot {
        position: relative;
        top: -1px;
        display: inline-block;
        width: 15px;
        height: 15px;
        vertical-align: middle;
        border-radius: 50%;
    }
}

.event-selector{
    .elements{
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

}

.profesional-selector{}

.request-list{}