@import '../../../styles/colors.scss';

.invoice-list-component{
    padding: 20px;
    .description{
        max-width: 200px;
    }

    .other-data{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .with-detail,
        .read-status{
            display: block;
            text-align:center;
            margin: 5px;

            .readed{
                color: green;
            }
        }
    }

    .paymentType{
        text-transform: uppercase;
    }

    .export-pdf-data{
        background-color: $red !important;
        border-color: $red !important;
        margin-right: 5px;
    }

    .invoice-number{
        text-align: center;
        .number{
            font-size: 12px;
            border-radius: 5px;
            padding: 1px 2px;
            text-align: center;
            background-color: $secondary-color;
            color: $white;
        }
    }

    .invoice-status{
        .status-tag{
            border-radius: 5px;
            padding: 1px 2px;
            width: 100px;
            text-align: center;

            .read-status{
                margin-left: 5px;
            }

            &.status-borrador{
                background-color: $blue;
                color: #fff;
            }

            &.status-abonada{
                background-color: $main-color;
                color: #fff;
            }

            &.status-emitida{
                background-color: $orange;
                color: #fff;
            }

            &.status-eliminada{
                background-color: $red;
                color: #fff;
            }
        }
    }
}