.keyword-component{
    padding:20px;

    
   
}

.btn-add-keyword-container{
    text-align: right !important;
    margin-top:5px !important;
}