.report-list{

    .readed-info{
        margin-left: 5px;
    }

    padding: 20px 20px 200px 20px;

    .not-readed{
        font-size: bolder;

        a{
            color: black;
            font-weight: 600;
        }
    }

    .massive-printer,
    .preformateado-masivo{
        margin-right: 5px;
    }

    .case-report-deleted{
    }

    .case-report-urgent{
        //background-color: #c183f321;
        .icon-urgent{
            font-size: 1.5em;
            margin-right: 13px;
        }
    }

    .previous-case-report-tag{
        cursor: pointer;
    }
    
    @keyframes IncidenciaVolanteAnimation{
        0% {
            transform: scale(1,1);
        }
        50% {
            transform: scale(1.1,1.1);
        }
        100% {
            transform: scale(1,1);
        }
    }

    .reception-status-tag{
        display: block;
        clear: both;
        /*animation-name: IncidenciaVolanteAnimation;
        animation-duration: 4s;
        animation-iteration-count: infinite;*/
        cursor: pointer;
        transition: all .3s;
        text-align: center;
        padding: 0 3px;
        margin-top: 5px;
        max-width: 180px;
    }

    .special-words{
        margin-top: 5px;
        display:block;
    }

    


    .case-report-status-tag{
        width: 90px;
        text-align: center;
    }

    
}