.user-list{

    .add-user-button{
        text-align: right;
        margin-bottom: 10px;
    }

    .users-pagination{
        margin-top: 10px;
        float:right;
    }

    .connected{
        border-radius:50%;
        background-color: green;
        min-width: 12px;
        min-height: 12px;
        width: 12px;
        height: 12px;
    }

    .no-connected{
        border-radius:50%;
        background-color: red;
        min-width: 12px;
        min-height: 12px;
        height: 12px;
        width: 12px;

    }

    .tag{
        border-radius: 5px;
        padding: 1px 2px;
        width: 2em;
        text-align: center;

        &.ok{
            background-color: #23c6c8;
            color: #fff;
        }
        &.ko{
            background-color: #ed5565;
            color: #fff;
        }
        &.koko{
            background-color: #f8ac59;
            color: #fff;
        }
    }

    .role-tag{
        border: solid 1px;
        border-radius: 5px;
        color: white;
        padding: 1px 2px;
        width: 120px;
        text-align: center;

        &.role-1{
            // Administrador
            background-color: #66648b;
        }
        &.role-2{
            // Médico
            background-color: #e94b65;
        }
        &.role-7{
            // Patólogo
            background-color: #fc9948;
        }
        
        .icon{
            font-size: 17px;
            margin-right: 3px;
            position: absolute;
            left: 15px;
            top: 15px;
        }    
    }

    .user-resume{
        .resume-title{
            td:first-child{
                font-weight: bold;
                margin-bottom: 5px;
                width: 160px;
            }
        }
    }
}