.table-pagination{

    .mobile-view{
        .file-data{
            margin-bottom: 10px;

            .icon{
                font-size: 15px;
                color: #8c8c8c;
                clear:both;
                display: block;
                margin-bottom: 3px;
            }
        }
        
    }

    .filter-buttons{
        margin-bottom: 5px;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button{
            text-transform: capitalize;
        }

        button:nth-child(1){
            margin-left: 5px;
            min-width: 100px;
        }
    }

    .user-resume{
        background-color: white !important;
        padding: 3px;
    }
    table{
        tbody{
            .ant-table-row:nth-child(even){
                background: #fcfcfc !important;
            }
        }
    }


    width: 100%;
    
    .pagination{
        float: right;
        margin-top: 10px;
    }

    .bottom-spacer{
        margin-top: 100px;
    }
}