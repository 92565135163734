.companies-list{

    .company-resume{
        .resume-title{
            td:first-child{
                font-weight: bold;
                margin-bottom: 5px;
                width: 160px;
            }
        }
    }

    .add-company{
        text-align: right;
        margin-bottom: 10px;
    }

    .companies-pagination{
        float: right;
        margin-top: 10px;
    }
}