@import 'custom.scss';

.theme{
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.4);
    background-blend-mode: lighten;
    
 
    .container{
        box-shadow: 0 0 52px 5px rgba(0,0,0,.3);
        height: 100vh;
        min-height: 100vh;
        max-height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color:white;

        @media (max-width: 768px) {
            box-shadow: none;
        }
    }
}