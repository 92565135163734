.frm-register{
    .coincidences{
        margin-bottom: 20px;

        .use-coincidence{
            margin-right: 5px;
        }

        ul{
            padding: 0;
        }
    }
}