@import '../../../styles/colors.scss';

.case-report-detail-component{
    padding: 10px 60px;

    @media(max-width: 768px){
        padding: 10px 5px;

        .ant-row.ant-form-item.ant-form-item-has-success{
            display: flex;
            flex-direction: inherit;
            margin-top: 60px;
        }

        .ant-descriptions-row{
            display:flex;
            flex-direction: column;
            width: 100%;

            th{
                width: 200%;
            }
        }

    }

    .ant-layout-header{
        &.menu{
            @media(max-width: 850px){
                width: 100% !important;
                padding: 0 !important;
            }
            
        }
    }

    &.sticky{
        .menu{
            position: fixed;
            top: 0px;
            z-index: 10;
            width: 100%;
            left: 0px;
            background: $main-color;
            box-shadow: 0 2px 8px $secondary-color;
            transition: background .3s ease-in;

            h3{
                color: $white;
                padding-top: 5px;
            }

            .actions{
                padding-left: 80px;

                @media(max-width: 768px){
                    padding-left: 20px !important;
                }
            }

            

        }

        .section:nth-child(1){
            margin-top: 70px;
        }
    }

    .menu{
        z-index: 1;
        left: 0px;
        height: 70px !important;
        background: $third-color;
        transition: background .1s ease-out;

        .preforms{
            width: 500px;
        }

        .btn-preforms{
            margin-left: 10px;
        }

        button{
            margin-right: 10px;
        }

        h3{
            margin:0;
            padding: 0;
            height: 1em;
            line-height: 1em;
        }

        
    }

    .commission{
        margin-bottom: 50px;
    }

    .block-element{
        display:block;
    }

    .btn-update{
        margin-top: 5px;
        float:right;
        margin-bottom: 5px;
    }

    .section{
        padding-top:20px;
    }
}