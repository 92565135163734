$main-color: #aa60f0;
$secondary-color: #c183f3;
$third-color: #fff;
$disabled-color: #d9d9d9;

$white: #ffffff;
$green: #00b300;
$red: #ff0000;
$violet: rgb(22, 2, 135);
$orange: #ffa500;
$unpaid: #ff9800;
$blue: #1c84c6;

$request-pending: #1c84c6;
$request-ordered: #ED55B8;
$request-delivered: #1ab394;
$request-processing:#f8ac59;
$request-finished:#ed5565;
$request-canceled:darkgray;