.statistics-page{
    padding: 20px;

    .statistics-header{
        margin-bottom: 20px;
    }

    .year-vs-container{
        margin-bottom: 10px;
        display:flex;
        align-items: center;
        .vs-year{
            margin: 5px;
            max-width: 100px;
        }
    }
}