.add-company{
    .btn-go-back{
        text-align: right;
        padding-top: 10px;
    }

    .add-company-buttons{
        text-align: right;
        

        button[class*='btn-']{
            width: 160px;
            margin-left: 10px;
            margin-top: 10px;

            @media only screen and (max-width: 400px) {
                width: 100%;
                margin-bottom: 5px;
                margin-top: 5px;
              }
        }
    }
}