.helper-component{
    background-color: red;

    @keyframes move-in{
        from { right: -100px }
        to { right: 20px }
    }
    
    @keyframes move-out{
        from { right: 20px }
        to { right: -100px }
    }

    &.helper-visible{
        .helper-balls{
            animation-duration: 0.1s;
            animation-name: move-in;
        }
    }

    &.helper-hidden{
        .helper-balls{
            animation-duration: 0.1s;
            animation-name: move-out;
        }
    }

    .helper-balls{
        animation: forwards;
        position: fixed;
        z-index: 10;
        list-style: none;
        padding: 0;

        .h-section-name{
            display: none;
            visibility: hidden;
            
           
            
        }
        
    
        
    
        &.dashboard-balls{
            background-color:transparent;
    
            
    
           
        }
    
    
        li:not(.created-by-omnicode){
            
    
            &.h-heart{
                color: red !important;
                position: fixed;
                bottom: 20px;
    
                &:hover{
                    background-color: red;
                    color: white !important;
                }
    
                
            }
    
            color: black;
            height: 55px;
            width: 55px;
            background-color: white;
            margin: 10px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 5px 14px rgba(0,0,0,.4);
            box-sizing: border-box;
            cursor: pointer;
            font-size: 1.6em;
            transition: all .4s;
    
            &.h-generic{
                //background-color: rgba(121,92,148,1) !important;
                //https://www.iteramos.com/pregunta/84155/como-dibujar-un-circulo-sector-en-css
                background-image: linear-gradient(0deg, #795c94 50%, #693f91 50%) !important;
                color:white;

                &.disabled{
                    background-image: linear-gradient(0deg, #dadada 50%, #a7a7a7 50%) !important;
                    cursor: not-allowed;
                }
            }
            &:hover{
                background-color: #4b7cf3;
                color: white;
                font-size: 2em;
                transition: all .4s;
            }
    
            &.h-logout{    
                background-image: linear-gradient(0deg, #ee3e3e 50%, #ee2828 50%) !important;
                color: white;
            }
    
            &.h-enrollments{
                background-color: rgba(255,200,0,.8);
                color: white;
            }
    
            &.h-contact{
                background-color: rgba(29, 178, 65, .93%);
                color: white;
            }
    
            &.h-credit-card{    
                background-color: rgba(0,0,255,.7);
                color: white;
            }
    
            &.h-notifications{    
                background-color: #FFC107;
                color: white;
    
                .h-alerts-count{
                    position: absolute;
                    border-radius: 100%;
                    background-color: red;
                    width: 25px;
                    height: 25px;
                    font-size: 12px;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 20px;
                    margin-top: 20px;
    
                    &.no-alerts{
                        display:none;
                    }
                }
            }
        }
    }
}


