.detailed-statistics-component{
    padding: 20px;

    .btn-load-case-reports{
        float:right;
    }

    .filters{
        .dp-filter-dates, 
        .select-doctor{
            margin-right: 5px;
        }
        
    }

    .table-age-range{
        
        .header{
            background-color: #fafafa;
            border-bottom: solid 1px #d9d9d9;
            padding: 10px;

            &.sup{
                border-top: solid 1px #d9d9d9;
            }
        }

        

        tr:nth-child(odd){
            background-color: #fafafa;
        }

        tr{
            &:hover{
                td:nth-child(1){
                    font-weight: 600 !important;
                }
                td{
                    font-weight: 700;
                }
                background-color: #fafafa;
                cursor: pointer;
            }
        }


        .table-bold{
            font-weight: 400;
        }
    
        .text-centered{
            text-align: center;

            &.has-data{
                font-weight: 700;
            }
        }

        .type{
            margin-left: 10px;
        }
    }

    .full-width{
        width: 100%;
    }

    .btn-statistics-option{
        text-align: left;
        margin-bottom: 5px;
    }

    .bordered-col{
        border: solid;
        border-color: #e6e6e6;

        &.top{
            border-width: 1px 0 0 0;
            
        }

        &.right{
            border-width: 0 1px 0 0;
        }

        &.left{
            border-width: 0 0 0 1px;
        }

        &.bottom{
            border-width: 0 0 1px 0;
        }

        &.none{
            border-width: 0;
        }
    }

    .extended{
        margin-top: 10px;
    }

    .statistics-info{
        padding: 0 5px;
        span:nth-last-child(2){
            float: right;
            font-weight: 500;
        }

        .statistics-info-divider{
            margin: 5px !important;
        }
    }


}