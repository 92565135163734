.session{
    border-left: solid 1px #F0F0ED;
    padding-left: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 768px){
        padding: 3px 20px 9px 3px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .user-profile-access{
        font-size: 1.5em;
        border: solid 1px transparent;
        padding: 3px;
        border-radius: 50%;
        background-color: #c183f3;
        color: white;
        cursor: pointer;

        &.role-1{
            //Administrador
            background-color: #66648b;
        }

        &.role-2{
            //Doctor
            background-color: #e94b65;
        }

        &.role-7{
            //Patólogo
            background-color: #fc9948;
        }
    }

    .username{
        text-transform: uppercase;
        text-overflow: ellipsis;
        padding-left: 5px;
        color:#c183f3;
        font-size: 12px;
    }

    .logout,
    .pin-page{
        border-radius: 50%;
        color: white;
        font-size: large;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
    }
    
    .logout{
        background: linear-gradient(0deg, #f51212 50%, #ff1414bd 50%) !important;
    }

    .pin-page{
        background: linear-gradient(0deg, #795c94 50%, #693f91 50%) !important;
        
        &.pinned{
            background: linear-gradient(0deg, #76bb8d 50%, #90e2ab 50%) !important;
            transition: background 5s;
        }
    }

}