@import '../../styles/colors.scss';

.request-page{
    padding: 20px;


    .available-event{
        cursor: pointer;
        min-width: 206px;
        max-height: 32px;
        text-align: center;
        font-size: 12px !important;
        line-height: 1 !important;

        height: 30px !important;
        max-height: 30px !important;

        &:hover{
            background-color: #f7edbd !important;
            transition: background-color 0.3s ease;
        }
    }

    &.simple{
        padding: 0px;

        .box-component{
            padding: 0px;
        }

        .resume-list,
        .filter-menu{
            display: none;
            visibility: hidden;
        }
    }


    .button-filter-menu{
        margin: 0 2px;
        border-radius: 3px;
        button{
            background-color: $main-color;
            border: none;
            color: $white;
            cursor:pointer;
            width: auto !important;
            min-width: 50px;
        }
    }

    .daily-request-list{
        .list-status-title{
            text-decoration: underline;
            cursor: pointer;
        }

        .event-list-info{
            display: flex;
            flex-direction: column;
            span{
                padding: 0 3px;
            }
    
            span:first-child{
                border-bottom: solid 1px #f0f0f0;
            }
        }
    }

    .resume{
        display: flex;
        flex-wrap: wrap;

        .pre-save-notify{
            margin-bottom: 4px;
            background-color: #fffbe6;
            border: 1px solid #ffe58f;
            box-sizing: border-box;
            color: rgba(0, 0, 0, 0.85);
            font-variant: tabular-nums;
            list-style: none;
            font-feature-settings: 'tnum', "tnum";
            align-items: center;
            padding: 8px 15px;
            word-wrap: break-word;
            border-radius: 20px;
            margin-right: 3px;

            min-width: 206px;
            height: 45px;
            max-height: 45px;
            text-align: center;
            font-size: 12px !important;
            line-height: 1.25;

            small{
                display:block;
            }

            .professional-name{
                display: block;
                padding-left: 25px;
                font-size: 11px;
                text-decoration: dotted underline;
                font-weight: 700;
            }
    
            .icon{
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }

    


    .btn-pre-save{
        margin-right: 5px;
    }

    .btn-save-request{
        width: calc(100% - 40px);
    }
}


