.advanced-search-component{
    .btn-add-criteria{
        width: 100%;
    }

    .sql-tag{
        margin:3px;
    }

    .symbol{
        margin-right: 3px;
    }

    .free-where{
        margin-top: 5px;

        .free-where-input{
            max-width: 350px;
        }

        .btn-free-where{
            margin-left: 10px;
        }
    }
}