.statistics-component{
    padding: 20px;

    .current-year{
        text-decoration: dashed underline 1px;
        cursor: pointer;
    }

    .text-right{
        text-align: right;
    }
}