.search{
    border-left: solid 1px #F0F0ED;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;



    .title-result{
        border-bottom: solid 1px #F0F0ED;
        width: 100%;
        margin-bottom: 5px;

        .shortcut{
            .anticon{
                font-size: 12px !important;
            }
        }
    }

    .search-list{
        margin-bottom: 3px;

        .ant-list-header,
        .ant-list-footer{
            font-weight: 500;
        }

        .case-report-sex-tag{
            .anticon{
                font-size: 12px !important;
            }
        }

        .link{
            border-bottom: dashed 1px #b6b6b1;
            padding: 0 3px;
            display: inline-block;
            cursor: pointer;

            .see{
                font-size: 15px !important;

                &.small{
                    font-size: 12px !important;
                }
            }
        }
    }

    .search-input{
        border: none;
        .search-icon{
            color:#d3d3ce;
        }
        margin: 10px 0px;
        width: 360px;
        @media(max-width: 768px){
            width: 100%;
            border-bottom: 1px solid #F0F0ED;
        }
    }

    .search-result{

        &.hidden{
            display: none;
        }

        width: 360px;
        @media(max-width: 768px){
            width: calc(100% - 20px);
        }

        position:absolute;
        z-index: 999;
        background-color: white;
        border-radius: 3px;
        border: solid 1px #F0F0ED;
        box-shadow: 0 2px 8px #f0f1f2;
        padding: 10px;
        margin-top: -5px;

        .anticon{
            font-size: 40px !important;
        }

        .loading-data{
            text-align: center;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 5px;
            .anticon{
                font-size: 18px !important;
                margin-right: 10px;
            }
        }

    }

    .btn-search{
        color:"#d3d3ce";
        cursor: pointer;
    }
}