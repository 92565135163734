@import '../../../styles/colors.scss';

.invoice-generator{
    padding: 20px;
    

    .result{
        border-left: solid 1px #d9d9d9;

        .empty{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
        }

        .commission{
            display: block;
        }

        .invoice-actions{
            text-align: right;

            button{
                margin-left: 5px;
            }
        }

        .reduced-price{
            color: green;
        }

        .totals-resume{
            float: right;
            small{
                margin-right: 5px;
                font-size: 12px;

                strong{
                    color: black !important;
                    font-weight: 600 !important;
                }

                &.total-sales{
                    color: $violet;
                }
                
                &.total-company{
                    color: $orange;
                }

                &.total-net{
                    color: $secondary-color;
                    font-weight: 700;
                }
            }
        }
    }

    .options{
        .change-mode{
            margin-bottom: 5px;
            margin-right: 5px;
        }

        .ant-form-item{
            margin-bottom: 10px !important;
        }

        .right-position{
            float: right;
            margin-left: 3px;
        }

        .ultra-small{
            max-height: 15px;
            font-size: 10px;
        }
    }
}

.invoice-params-component{
    .subtitle{
        display: block;
        margin-bottom: 20px;
    }
    .modify-billing-data{
        margin-bottom: 10px;
    }

    .btn-actions{
        text-align: right;
    }

    .invalid-data-resume{
        text-align: justify;

        ul{
            margin-top: 10px;
            text-align: left;
            
            li{
                margin-bottom: 5px;
                small{
                    display: block;
                }

                .has-error{
                    color: red;
                }

                .no-errors{
                    color: green;
                }
            }
        }
    }
}