@import '../../styles/colors.scss';

.hello{
    border-radius: 5px;
    padding: .5em;
    //background-image: radial-gradient(circle at -13.87% 32.88%, #074376 0, #0d5089 25%, #0d5691 50%, #11669e 75%, #1268a6 100%);

    .title{
        color: white !important;
        padding-top: 5px;
    }

    .last-connection{
        color: white;
        text-align: right;
        width: 100%;
        font-weight: 600;
        position: absolute; 
        bottom: 5px;
        padding-right: 1em;
    }

    .client-logo{
        max-height: 80px;
    }

    .statistics-advanced{
        position: fixed;
        border: solid $secondary-color;
        border-width: 1px 1px 1px 0;
        padding: 5px;
        border-radius: 0 5px 5px 0;
        background-color: $secondary-color;
        color:$white;
        z-index: 10;
        box-shadow: 1px 1px 5px $secondary-color;
        .graph-icon{
            font-size: 18px;
        }
    }
}