.register{

    
    .ant-row ant-form-item{
        margin-bottom: 5px !important;
    }
}

.footer-add-patient{
    padding: 10px;
    text-align: right;
}

.footer-search-patient{
    position: absolute;
    margin-top: 10px;
    margin-left: 10px;
}

.more-info-rq{
    .title{
        text-align: center;
        .clock{
            display: block;
            font-size: 5em;
            color: lightgrey;
            margin-bottom: 10px;
        }
    }

    .resume{
        small{
            display: block;
        }
    }
}


.frm-register{
    padding-bottom: 100px;
}