.request-list{
    padding: 20px;

    .status-icon{
        display: flex;
        align-items: center;
    }

    .timeline{
        display:block;
        font-weight: 500;
        text-transform: capitalize;
    }
}