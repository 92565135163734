.migration-patients-component{
    padding: 20px;

    .advise{
        display:block;
        font-size: 12px;
    }

    .divider{
        margin: 0;
    }

    .patient-search{
        margin-top: 10px;
    }
}